import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../App";
import manager from "../utils/encryption";
import { LOCAL_STORAGE_DATA, LOCAL_STORAGE_TOKEN } from "../utils/variables";

const authContext = createContext<{
  auth: boolean;
  action: (
    type: "signin" | "signout" | "validateOTP" | "signin_with_sso",
    data?: any
  ) => void;
  qr_code_url: any;
}>({
  auth: false,
  action: (type, data) => {},
  qr_code_url: undefined,
});

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

  const [auth, setAuth] = useState<boolean>(() => {
    const admin_data = localStorage.getItem(LOCAL_STORAGE_DATA);

    return !!admin_data && !!token;
  });
  const [admin_data, setAdminData] = useState<Record<string, unknown>>({});

  const signin = (data: any) => {
    const admin_data = {
      email: data.email,
      first_name: data.first_name,
      image_url: data.image_url,
      last_name: data.last_name,
      first_time_login: data.first_time_login,
      permissions: data.user_all_permissions,
      two_fa_enabled: data.is_setup_two_factor_authentication,
      ...(data.qr_code_url ? { qr_code_url: data.qr_code_url } : {}),
    };

    manager.decrypt(admin_data);

    setAdminData({ ...admin_data, token: data.token });
    localStorage.setItem(LOCAL_STORAGE_TOKEN, data.token);

    if (admin_data.first_time_login === "False") {
      return navigate(
        admin_data.two_fa_enabled === "False"
          ? `?two_factor=setup-two-factor&email=${admin_data.email}`
          : `?two_factor=validate-otp&email=${admin_data.email}`
      );
    }

    navigate(`?first_time=${admin_data.email}`);
  };
  const signout = () => {
    queryClient.clear();
    localStorage.clear();
    setAuth(false);
    navigate("/");
  };

  const validateOTP = ({ token }: { token: string }) => {
    const decryptedToken = manager.decrypt(token);
    const { qr_code_url, two_fa_enabled, ...rest } = admin_data;
    localStorage.setItem(LOCAL_STORAGE_DATA, JSON.stringify(rest));
    localStorage.setItem(LOCAL_STORAGE_TOKEN, decryptedToken as string);
    setAuth(true);
    navigate("/");
  };

  function signinWithSso(data: any) {
    const admin_data = {
      email: data.email,
      first_name: data.first_name,
      image_url: data.image_url,
      last_name: data.last_name,
      first_time_login: data.first_time_login,
      permissions: data.user_all_permissions,
    };
    manager.decrypt(admin_data);

    // if (admin_data.first_time_login === "False") {
    //   localStorage.setItem(LOCAL_STORAGE_DATA, JSON.stringify(admin_data));
    //   localStorage.setItem(LOCAL_STORAGE_TOKEN, data.token);
    //   setAuth(true);
    // } else {
    //   navigate(`?first_time=${admin_data.email}`);
    // }
    localStorage.setItem(LOCAL_STORAGE_DATA, JSON.stringify(admin_data));
    localStorage.setItem(LOCAL_STORAGE_TOKEN, data.token);
    setAuth(true);
  }

  const action = (
    type: "signin" | "signout" | "validateOTP" | "signin_with_sso",
    data?: any
  ) => {
    if (type === "signin") signin(data);
    if (type === "signout") signout();
    if (type === "validateOTP") validateOTP(data);
    if (type === "signin_with_sso") signinWithSso(data);
  };

  return (
    <authContext.Provider
      value={{
        auth,
        action,
        qr_code_url: admin_data.qr_code_url,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(authContext);
