import { Tooltip } from "antd";
import {
  Activity,
  CardReceive,
  ClipboardTick,
  Clock,
  Coin,
  Edit,
  LogoutCurve,
  Sms,
  Trade,
  TrendUp,
  User,
  UserEdit,
  UserTick,
  Verify,
  Wallet,
} from "iconsax-react";
import { FiUsers } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../contexts/auth";
import { usePermissions } from "../../../contexts/permissions";
import useFetchAdminData from "../../../hooks/utility/useFetchAdminData";
import { usePullData } from "../../../hooks/useFetch";

const Sidebar = ({ expanded, toggleSidebar }: any) => {
  const { data } = usePullData({ uri: "admin/pending-info" });

  const { ADMIN_PAGE_PERMISSIONS } = usePermissions();

  const { action } = useAuth();
  const localStorage = useFetchAdminData();

  const routes = [
    {
      to: "/",
      icon: (
        <div className="h-4 w-4 xl:h-5 xl:w-5">
          <img
            src="/images/exported-icons/dashboard-icon.png"
            className="w-full h-full"
            alt="comx admin"
          />
        </div>
      ),
      name: "Dashboard Overview",
      value: ADMIN_PAGE_PERMISSIONS.DASHBOARD,
    },
    {
      to: "trades",
      icon: <Trade className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Trades",
      value: ADMIN_PAGE_PERMISSIONS.TRADE,
    },
    {
      to: "clients",
      icon: <User className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Clients",
      value: ADMIN_PAGE_PERMISSIONS.CLIENTS,
    },
    {
      to: "client_assets",
      icon: <Wallet className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Client Assets",
      value: ADMIN_PAGE_PERMISSIONS.CLIENT_ASSETS,
    },
    {
      to: "client_details_update",
      icon: <UserEdit className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Client Details Update",
      value: ADMIN_PAGE_PERMISSIONS.EDIT_CLIENT_DETAIL,
    },
    {
      to: "corporate_details_update",
      icon: <UserEdit className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Corporate Details Update",
      value: ADMIN_PAGE_PERMISSIONS.EDIT_CLIENT_DETAIL,
    },
    {
      to: "community",
      icon: <FiUsers className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Community",
      value: ADMIN_PAGE_PERMISSIONS.COMMUNITY,
    },
    {
      to: "funding",
      icon: <CardReceive className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Funding & Payments",
      value: ADMIN_PAGE_PERMISSIONS.FUNDING_AND_PAYMENTS,
    },
    {
      to: "loan_report",
      icon: <Coin className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Loan Report",
      value: ADMIN_PAGE_PERMISSIONS.LOAN_REPORT,
    },
    {
      to: "content_management",
      icon: <Edit className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Content Management",
      value: ADMIN_PAGE_PERMISSIONS.CONTENT_MANAGEMENT,
    },
    {
      to: "promoter_management",
      icon: <FiUsers className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Promoter Management",
      value: ADMIN_PAGE_PERMISSIONS.PROMOTER_MANAGEMENT,
      count: data?.pending_promoters_count ?? 0,
    },
    {
      to: "broker_management",
      icon: <FiUsers className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Corporate Management",
      value: ADMIN_PAGE_PERMISSIONS.BROKER_MANAGEMENT,
      count: data?.pending_corporate_clients_count ?? 0,
    },
    {
      to: "client_kyc_verification",
      icon: <UserTick className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Client KYC Verification",
      value: ADMIN_PAGE_PERMISSIONS.CLIENT_KYC_VERIFICATION,
      count: data?.clients_with_pending_kyc_count ?? 0,
    },
    {
      to: "market_report",
      icon: <TrendUp className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Market Report",
      value: ADMIN_PAGE_PERMISSIONS.MARKET_REPORT,
    },
    {
      to: "send_blast_sms",
      icon: <Sms className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Send Blast SMS",
      value: ADMIN_PAGE_PERMISSIONS.SEND_BLAST_SMS,
    },
    {
      to: "administration",
      icon: <User className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Administration",
      value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION,
    },
    {
      to: "tracker_dashboard",
      icon: <Clock className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Tracker Dashboard",
      value: ADMIN_PAGE_PERMISSIONS.TRACKER_DASHBOARD,
    },
    {
      to: "audit_log",
      icon: <ClipboardTick className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Audit Log",
      value: ADMIN_PAGE_PERMISSIONS.AUDIT_LOG,
    },
    {
      to: "sec_verification",
      icon: <Verify className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "SEC Verification",
      value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION_CLIENT_MANAGEMENT,
    },
    {
      to: "activity_stream",
      icon: <Activity className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Activity Stream",
      value: ADMIN_PAGE_PERMISSIONS.ACTIVITY_STREAM,
    },
    {
      to: "admin_profile",
      icon: <User className="h-4 w-4 xl:h-5 xl:w-5" />,
      name: "Admin Profile",
      value: ADMIN_PAGE_PERMISSIONS.ADMIN_PROFILE,
    },
  ];

  return (
    <aside
      id="sidebar-parent"
      className={`fixed bg-white ${
        expanded ? "flex" : "hidden"
      } lg:flex flex-col top-16 left-0 ${
        expanded ? "w-full lg:w-64" : "lg:w-[13%] 2xl:w-[11%]"
      } h-full text-[#54565B] transition-all duration-300 z-10`}
    >
      <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <div className="flex flex-col pt-7 xl:pt-10 pb-20 space-y-7 xl:space-y-10">
          {/* <span className='pl-10 py-8 hidden lg:flex'>
                        <HambergerMenu onClick={toggleSidebar} className='h-6 w-6 cursor-pointer' />
                    </span> */}
          {routes
            .filter((route) =>
              localStorage.permissions.find((permission: any) => {
                return Array.isArray(route.value)
                  ? route.value.find(
                      (item) => permission === `can_view_${item}`
                    )
                  : permission.includes(route.value);
              })
            )
            .map((route) => {
              return (
                <NavLink
                  to={route.to}
                  key={route.name}
                  className={({ isActive }) =>
                    `${
                      isActive && "text-[#E1261C]"
                    } mx-3 xl:mx-5 items-center hover:text-[#E1261C] cursor-pointer`
                  }
                >
                  <Tooltip
                    className="flex flex-row gap-2 2xl:gap-4 items-center"
                    title={route.name}
                    placement="right"
                    key={route.name}
                    color="#000"
                  >
                    <span>{route.icon}</span>
                    <span className="text-xs xl:text-base truncate">
                      {route.name}
                    </span>
                    {route.count && route.count > 0 ? (
                      <div className="bg-[#E1261C] text-white rounded-full px-2 py-1 text-[8px] font-semibold">
                        {route.count}
                      </div>
                    ) : null}
                  </Tooltip>
                </NavLink>
              );
            })}
          <div
            onClick={() => action("signout")}
            className={`mx-3 xl:mx-5 hover:text-[#E1261C] cursor-pointer`}
          >
            <Tooltip
              className="flex flex-row gap-2 2xl:gap-4 items-center"
              title={"Logout"}
              placement="right"
              color="#000"
            >
              <span>
                <LogoutCurve className="h-4 w-4 xl:h-5 xl:w-5 rotate-180" />
              </span>
              <span className="text-xs xl:text-base">Logout</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
